import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { RootState } from './types';
import suggestionReducer from '../common/pages/suggestions/suggestion.slice';
import sidebarReducer from '../administration/components/sidebar/sidebar-slice';
import userAdminFiltersReducer from '../administration/pages/administration/dashboard/users/slices/filters.slice';
import AdminUsersReducer from '../administration/pages/administration/dashboard/users/slices/admin-users.slice';
import toastReducer from '../administration/components/toast/toast.slice';
import countrySlice from '../administration/pages/administration/dashboard/countries/slices/country.slice';
import usecasePanelReducer from '../administration/pages/administration/dashboard/usecase/slices/usecase-panel.slice';
import usecasesReducer from '../administration/pages/administration/dashboard/usecase/slices/usecases.slice';
import auditReducer from '../administration/pages/administration/dashboard/audit/slices';
import hcpsReducer from '../common/slices/hcpSlice';
import hcosReducer from '../common/slices/hcoSlice';
import productsReducer from '../common/slices/productsSlice';
import usersReducer from '../common/slices/userSlice';
import suggestionsFilterReducer from '../common/components/filters/filter.slice';
import personalSettingsReducer from '../common/pages/settings/settings.slice';
import mapReducer from '../common/pages/suggestions/map.slice';
import directionsReducer from '../common/pages/suggestions/directions.slice';
import themeReducer from '../common/theme/theme.slice';
import userDetailsReducer from '../administration/pages/administration/dashboard/user-page/slices/user-details.slice';
import userDetailsModalReducer from '../administration/pages/administration/dashboard/user-page/slices/details-modal.slice';
import appSetupFormReducer from '../administration/pages/administration/dashboard/app-setup/slices/app-setup.slice';
import MSTeamsSetupReducer from '../administration/pages/administration/dashboard/msteams-setup/slices/msteams-setup.slice';
import MSTeamsActionsReducer from '../administration/pages/administration/dashboard/msteams-setup/slices/msteams-actions.slice';

import {
  ADMIN_SIDEBAR,
  ADMIN_USER_FILTERS,
  ADMIN_USERS,
  AUDIT,
  COUNTRIES,
  SUGGESTIONS,
  TOAST,
  USECASE_PANEL,
  USECASES,
  HCPS,
  HCOS,
  PRODUCTS,
  SUGGESTIONS_FILTER,
  PERSONAL_SETTINGS,
  USERS,
  MAP,
  DIRECTIONS,
  THEME,
  USER_DETAILS,
  USER_DETAILS_MODAL,
  APP_SETUP,
  MS_TEAMS_SETUP,
  MS_TEAMS_SETUP_ACTIONS,
} from './constants';

const rootReducer = combineReducers({
  [SUGGESTIONS]: suggestionReducer,
  [ADMIN_SIDEBAR]: sidebarReducer,
  [ADMIN_USERS]: AdminUsersReducer,
  [ADMIN_USER_FILTERS]: userAdminFiltersReducer,
  [TOAST]: toastReducer,
  [COUNTRIES]: countrySlice,
  [USECASE_PANEL]: usecasePanelReducer,
  [USECASES]: usecasesReducer,
  [AUDIT]: auditReducer,
  [HCPS]: hcpsReducer,
  [HCOS]: hcosReducer,
  [PRODUCTS]: productsReducer,
  [USERS]: usersReducer,
  [SUGGESTIONS_FILTER]: suggestionsFilterReducer,
  [PERSONAL_SETTINGS]: personalSettingsReducer,
  [MAP]: mapReducer,
  [DIRECTIONS]: directionsReducer,
  [THEME]: themeReducer,
  [USER_DETAILS]: userDetailsReducer,
  [USER_DETAILS_MODAL]: userDetailsModalReducer,
  [APP_SETUP]: appSetupFormReducer,
  [MS_TEAMS_SETUP]: MSTeamsSetupReducer,
  [MS_TEAMS_SETUP_ACTIONS]: MSTeamsActionsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const storeTest = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
