import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { RootState } from '@store/types';
import {
  IMsTeamsActionsState,
  IMsTeamsUserActionsPayload,
} from '@administration/interfaces/IAppSetup';
import { communicationApi } from '@administration/apis/app-setup/msteams';
import { displayToast } from '@administration/components/toast/toast.slice';

const showErrorToast = (dispatcher: any) =>
  dispatcher(
    displayToast({
      type: 'error',
      message: t('GLOBAL_ERROR'),
    }),
  );

export const checkMsTeamsInstallation = createAsyncThunk(
  'msTeams/checkInstallation',
  async (
    { user_id, channel = 'ms_team' }: IMsTeamsUserActionsPayload,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await communicationApi.postVerificationMessage({
        user_id,
        channel,
      });
      if (response.status >= 200 && response.status < 300) {
        return response.data.status;
      }
    } catch (error) {
      showErrorToast(dispatch);
      return rejectWithValue('GLOBAL_ERROR');
    }
  },
);

export const sendWelcomeMsg = createAsyncThunk(
  'msTeams/sendMessage',
  async (
    { user_id, channel = 'ms_team' }: IMsTeamsUserActionsPayload,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await communicationApi.postWelcomeMessage({
        user_id,
        channel,
      });
      if (response.status >= 200 && response.status < 300) {
        return response.data.values;
      }
    } catch (error) {
      showErrorToast(dispatch);
      return rejectWithValue('GLOBAL_ERROR');
    }
  },
);

export const sendSuggestionMsg = createAsyncThunk(
  'msTeams/sendSuggestion',
  async (
    { user_id, recipient_id, channel = 'ms_team' }: IMsTeamsUserActionsPayload,
    { getState, rejectWithValue, dispatch },
  ) => {
    const state = getState() as RootState;
    const sendItToMeEnabled = state.msTeamsActions.sendToMe;
    try {
      const response = await communicationApi.postSuggestionMessage({
        user_id,
        channel,
        recipient_id: sendItToMeEnabled ? recipient_id : null,
      });
      if (response.status >= 200 && response.status < 300) {
        return response.data.values;
      }
    } catch (error) {
      showErrorToast(dispatch);
      return rejectWithValue('GLOBAL_ERROR');
    }
  },
);

const initialState: IMsTeamsActionsState = {
  sendToMe: false,
  verification: {
    loading: false,
    error: null,
    responseStatus: null,
  },
  welcomeMsg: {
    loading: false,
    error: null,
    responseStatus: null,
  },
  suggestionMsg: {
    loading: false,
    error: null,
    responseStatus: null,
  },
};

const msTeamsActionsSlice = createSlice({
  name: 'msTeams/actions',
  initialState,
  reducers: {
    toggleSendToMe(state) {
      state.sendToMe = !state.sendToMe;
    },
    clearStatus(state, action: PayloadAction<'verification' | 'welcomeMsg' | 'suggestionMsg'>) {
      state[action.payload].responseStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // CheckMsTeamsInstallation
      .addCase(checkMsTeamsInstallation.pending, (state) => {
        state.verification.loading = true;
        state.verification.error = null;
      })
      .addCase(checkMsTeamsInstallation.fulfilled, (state, action) => {
        state.verification.loading = false;
        state.verification.responseStatus = action.payload;
      })
      .addCase(checkMsTeamsInstallation.rejected, (state, action) => {
        state.verification.loading = false;
        state.verification.error = action.payload as string;
      })
      // SendWelcomeMsg
      .addCase(sendWelcomeMsg.pending, (state) => {
        state.welcomeMsg.loading = true;
        state.welcomeMsg.error = null;
      })
      .addCase(sendWelcomeMsg.fulfilled, (state, action) => {
        state.welcomeMsg.loading = false;
        state.welcomeMsg.responseStatus = action.payload;
      })
      .addCase(sendWelcomeMsg.rejected, (state, action) => {
        state.welcomeMsg.loading = false;
        state.welcomeMsg.error = action.payload as string;
      })
      // SendSuggestionMsg
      .addCase(sendSuggestionMsg.pending, (state) => {
        state.suggestionMsg.loading = true;
        state.suggestionMsg.error = null;
      })
      .addCase(sendSuggestionMsg.fulfilled, (state, action) => {
        state.suggestionMsg.loading = false;
        state.suggestionMsg.responseStatus = action.payload;
      })
      .addCase(sendSuggestionMsg.rejected, (state, action) => {
        state.suggestionMsg.loading = false;
        state.suggestionMsg.error = action.payload as string;
      });
  },
});

export const { toggleSendToMe, clearStatus } = msTeamsActionsSlice.actions;
export default msTeamsActionsSlice.reducer;
