import axios from 'axios';
import { GlobalVariables, config } from '../config/configurations';
import { getCookie } from '../helpers/cookies';
import { forceLogout } from './authentication';
import { app } from '@microsoft/teams-js';
import { refreshMS } from '@common/pages/login/ms-services';

const isAppInitialized = async (): Promise<boolean> => {
  try {
    await app.initialize();
    const appInitialized = app.isInitialized();

    return appInitialized;
  } catch (error) {
    return false;
  }
};

let instance = axios.create({
  withCredentials: true,
  baseURL: config.REACT_APP_URL_BACKEND_API,
  headers: {
    'Access-Control-Allow-Headers': 'Origin, Content-Type, x-access-token, X-Headers-Authorization',
    appId: config.REACT_APP_ID,
    version: config.REACT_APP_API_VERSION,
    client: config.REACT_APP_CLIENT,
    'X-Headers-Authorization': getCookie('x-access-token')
      ? 'x-cors-context'
      : 'x-same-site-context',
    Authorization: getCookie('x-access-token') ? `Bearer ${getCookie('x-access-token')}` : null,
  },
});

export function reinitializeInstance() {
  instance = axios.create({
    withCredentials: true,
    baseURL: config.REACT_APP_URL_BACKEND_API,
    headers: {
      'Access-Control-Allow-Headers': 'Origin, Content-Type, x-access-token',
      appId: config.REACT_APP_ID,
      version: config.REACT_APP_API_VERSION,
      client: config.REACT_APP_CLIENT,
      Authorization: getCookie('x-access-token') ? `Bearer ${getCookie('x-access-token')}` : null,
    },
  });
}

isAppInitialized().then((result) => {
  instance.defaults.headers['x-web-integration'] = result ? 'teams' : 'web';
});

instance.interceptors.response.use(
  (request) => {
    return request;
  },
  async (error) => {
    const globalVar = GlobalVariables.getInstance();
    const isOnLoginPage =
      (globalVar.integrationMode !== 'addon' && window.location.pathname === '/login') ||
      (globalVar.integrationMode === 'addon' && window.location.pathname === '/index.html#/login');
    if (
      error.request.responseURL.includes('oauth2/refresh') ||
      error.request.responseURL.includes('oauth2/logout')
    ) {
      return Promise.reject(error);
    }
    if (
      error &&
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      !isOnLoginPage
    ) {
      if (app.isInitialized()) {
        await refreshMS();
        return axios(error.config);
      }
      await instance
        .post(
          `${config.REACT_APP_URL_BACKEND_API}/${config.REACT_APP_API_VERSION}/authentication/oauth2/refresh`,
          {
            grant_type: 'refresh_token',
            instance: globalVar.getIntegrationMode(),
          },
        )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            forceLogout();
          }
          // eslint-disable-next-line no-console
          console.error('Error refreshing token', err);
          return Promise.reject(err);
        });
      return axios(error.config);
    }
    return Promise.reject(error);
  },
);

export { instance };
