import React from 'react';
import {
  Stack,
  Box,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { t } from 'i18next';

import { selectUsecasePanel } from '@store/selectors';
import { OPTIONS } from '../constants';
import { updateMarketshare } from '../slices/usecase-panel.slice';
import { useDispatch, useSelector } from 'react-redux';
import { CapFirst } from '../../../../../common/components/capitilizer';

export const MarketShare = () => {
  const dispatch = useDispatch();
  const { marketShare } = useSelector(selectUsecasePanel);

  return (
    <Stack spacing={1}>
      <ListItemButton sx={{ paddingLeft: '4px' }}>
        <FormControl>
          <Checkbox
            checked={marketShare.active}
            onChange={(e) => {
              dispatch(updateMarketshare({ value: e.target.checked, flag: 'CHECK' }));
            }}
          />
        </FormControl>
        <ListItemText
          primary={t(marketShare.title)}
          sx={{ '::first-letter': { textTransform: 'capitalize' }, maxWidth: '180px' }}
        />

        <FormControl
          sx={{ m: 1, minWidth: '120px', width: 'fit-content', alignSelf: 'end' }}
          size="small"
        >
          <InputLabel id="market-share-select">{t('GLOBAL_CHOOSE')}</InputLabel>
          <Select
            labelId="market-share-select"
            data-testid="market-share-select"
            value={marketShare.selectedOption}
            onChange={(e) => {
              dispatch(updateMarketshare({ value: e.target.value, flag: 'OPTION' }));
            }}
          >
            {OPTIONS?.map((option) => (
              <MenuItem key={option.label} value={option.name}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItemButton>
      {marketShare.active && (
        <>
          <Box display="flex" flexDirection="column" sx={{ width: '100%', paddingLeft: '25px' }}>
            <RadioGroup
              defaultValue="global"
              data-testid="settings-radio"
              value={marketShare.SettingType}
              onChange={(e) => {
                dispatch(updateMarketshare({ value: e.target.value, flag: 'SETTING_TYPE' }));
              }}
            >
              <FormControlLabel
                value="GLOBAL"
                control={<Radio />}
                label={t('USER_USE_GLOBAL_SETTINGS')}
              />
              <FormControlLabel
                value="AI_GENERATED"
                control={<Radio />}
                label={t('USE_AI_GENERATED_SETTING')}
              />
              <FormControlLabel
                value="SPECIFIC"
                control={<Radio />}
                label={t('USER_USE_SPEC_SETTINGS')}
              />
            </RadioGroup>
          </Box>
          {marketShare.SettingType === 'SPECIFIC' && (
            <Box display="flex" flexDirection="column" sx={{ width: '100%', paddingLeft: '25px' }}>
              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                <CapFirst variant="body1" content={t('MS_MIN_ACTIVITY')} />
                <TextField
                  data-testid="min-activity-input"
                  value={marketShare.activity_thresholds.minimal_activity.value}
                  onChange={(e) => {
                    dispatch(
                      updateMarketshare({
                        value: e.target.value,
                        flag: 'ACTIVITY',
                        vFlag: 'MINIMAL_VALUE',
                      }),
                    );
                  }}
                  type="number"
                  hiddenLabel
                  placeholder="200"
                  variant="outlined"
                  size="small"
                  sx={{ marginX: 2, width: 70 }}
                />
                <FormControl sx={{ m: 1, minWidth: '100px', width: 'fit-content' }}>
                  <Select
                    aria-label="measurement type"
                    value={marketShare.activity_thresholds.minimal_activity.type}
                    onChange={(e) => {
                      dispatch(
                        updateMarketshare({
                          value: e.target.value,
                          flag: 'ACTIVITY',
                          vFlag: 'MINIMAL_TYPE',
                        }),
                      );
                    }}
                    size="small"
                  >
                    <MenuItem value="MOT">{t('MONTH_OF_TREATMENT')}</MenuItem>
                    <MenuItem value="USD">{t('USD')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                <CapFirst variant="body1" content={t('MS_MID_ALERT')} />
                <TextField
                  data-testid="mid-alert-input"
                  value={marketShare.activity_thresholds.low_alert_level}
                  onChange={(e) => {
                    dispatch(
                      updateMarketshare({ value: e.target.value, flag: 'ACTIVITY', vFlag: 'LOW' }),
                    );
                  }}
                  type="number"
                  hiddenLabel
                  placeholder="5%"
                  variant="outlined"
                  size="small"
                  sx={{ marginX: 2, width: 70 }}
                />
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                <CapFirst variant="body1" content={t('MS_HIGH_ALERT')} />
                <TextField
                  data-testid="high-alert-input"
                  value={marketShare.activity_thresholds.high_alert_level}
                  onChange={(e) => {
                    dispatch(
                      updateMarketshare({ value: e.target.value, flag: 'ACTIVITY', vFlag: 'HIGH' }),
                    );
                  }}
                  type="number"
                  hiddenLabel
                  placeholder="10%"
                  variant="outlined"
                  size="small"
                  sx={{ marginX: 2, width: 70 }}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};
